.login-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #050505;
}

.left-section {
  display: flex;
  align-items: center;
  width: 50%;
}

.dummy-image {
  max-height: 100vh;
  max-width: 100%;
}

.overlay-text {
  position: absolute;
  top: 40%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: #fff;
}

.overlay-text h1 {
  letter-spacing: 0.125rem;
  font-size: 3rem;
  margin: 0;
}

.overlay-text p {
  width: 65%;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #8c8c8c;
  margin: 0;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: #1a1a1b;
}

.right-section .logo-mobile {
  display: none;
}

.login-form {
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}

.login-form h1 {
  letter-spacing: 0.125rem;
  font-size: 3rem;
  margin: 0;
}

.login-form p {
  font-size: 1.125rem;
  line-height: 1.5;
  color: #8c8c8c;
  margin: 0;
}

.login-form-forgot {
  float: right;
}

.login-form-forgot:hover {
  color: #1890ff;
}

@media (max-width: 767px) {
  .left-section {
    display: none;
  }

  .right-section {
    background-color: #050505;
  }

  .right-section .logo-mobile {
    display: block;
  }
  
}
